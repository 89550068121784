@mixin worship-gradient() {
  background-image: $in-gradient;
  color: #fff;
  &:hover {
    background-image: $in-gradient;
    cursor: pointer;
    color: #fff;
  }
}

@mixin block-overflow-to-transparent-left-right($color) {
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  &:before {
    background-image: linear-gradient(270deg, transparent 0%, $color 100%);
    left: -2px;
  }
  &:after {
    right: -2px;
    background-image: linear-gradient(90deg, transparent 0%, $color 100%);
  }
}

@mixin scrollbars($width, $height, $foreground-color, $background-color) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    height: $height;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin zoomInOut($hasShadow: true) {
  transition: transform $duration $function;
  transform: scale(1) translateY(0);
  will-change: transform, box-shadow;
  &:hover {
    transform: scale(1.05) translateY(-8px);
    @if ($hasShadow) {
      box-shadow: 0 44px 17px rgba(0, 0, 0, 0.37);
    }
  }
}

@mixin worship-transition(
  $property,
  $duration: $duration,
  $function: $function
) {
  @if not $property {
    @warn 'Property has to be passed';
  }
  will-change: $property; // TOFIX what about this https://www.digitalocean.com/community/tutorials/css-will-change I think it should be remove here in css.
  transition: $property $duration $function;
}

@mixin hover-darken {
  @include worship-transition(filter);
  &:hover {
    filter: brightness(10%);
  }
}

@mixin h3 {
  text-transform: uppercase;
  font-weight: $weight-bold; //700
  font-size: rem(14px);
  letter-spacing: 2.8px;

  @include tablet {
    font-size: rem(18px);
    letter-spacing: 3.4px;
  }
}

@mixin submitButton {
  width: 242px;
  height: 46px;
  border-radius: 23px;
  text-align: center;
  color: #fff;
  padding: 0 65px;
  border: 0;
  background-color: $purple;
  font-size: 0.7rem;
  line-height: 46px;
  letter-spacing: 3px;
  display: inline-block;

  &:hover {
    background-color: $purple;
  }

  @include mobile {
    width: 100%;
  }
}

@mixin verticalLine {
  height: inherit;
  width: 0;
  border: 1px solid $grey-wo;
  margin: 0 15px;
  @include mobile {
    display: none;
  }
}

@mixin dangerBorder {
  border: 2px solid $danger-color;
  border-radius: $radius;
}

@mixin errorAbsolute {
  position: absolute;
  font-weight: 600;
  letter-spacing: 1px;
  background-color: $danger-color;
  width: 100%;
  text-align: center;
  left: -1px;
  top: -1px;
  border-radius: $radius $radius 0 0;
  transform: scale(1.005);

  @include mobile {
    font-size: 14px;
    padding: 0;
  }

  @include tablet {
    padding: 1px;
  }

  @include widescreen {
    padding: 10px;
  }
}

@mixin error {
  input {
    border: solid $danger-color 2px;
    outline: none;

    @include mobile {
      border: solid $danger-color 1px;
    }
  }
  &Absolute {
    @include errorAbsolute();
  }
}

@mixin popup {
  &Subtitle {
    text-transform: capitalize;

    @include mobile {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
      margin-left: 9px;
    }

    @include tablet {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
      margin-left: 9px;
    }

    @include desktop {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
      margin-left: 12px;
    }
  }
  &Relative {
    position: relative;
  }

  &Delete {
    background-color: var(--modal-dark-block-background-color);
    bottom: 0;
    left: 0;
    padding: 30px 0 26px;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    p {
      font-size: 18px;
      font-weight: $weight-bold;
      opacity: 0.25;
      text-align: center;
      text-transform: uppercase;
    }
  }

  button {
    background-color: #b900bd;
    border-radius: 13px;
    color: $white;
    display: block;
    font-size: 18px;
    font-weight: $weight-bold;
    margin: 60px auto 0;
    padding: 20px 170px;
    text-transform: capitalize;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    @media (max-width: 480px), (min-width: 769px) and (max-width: 960px) {
      padding: 20px 0;
      width: 100%;
    }
  }
}
