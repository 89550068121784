// Default Vars
@import './variables_overrides';

$block-radius: $radius + 2px;
$block-bg: var(--block-background-color);
$header-height: 74px;
$header-height-tablet: 112px;
$header-height-desktop: 170px;

$in-gradient: linear-gradient(23deg, #b700ba 0%, $cyan 100%);
$lab-button-gradient: linear-gradient(270deg, $cyan -45.38%, #b700ba 124.76%);

$duration: 150ms;
$function: ease-out;

$weight-extra-bold: 900;

$grey-bg-lightest: #d3d3d3;
$grey-bg-light: #bebebe;
$grey-wo: #808080;
$grey-bg: #393939;

$button-border-radius: 34px;

$vocals-lead-color: #49c7f2;
$vocals-tenor-color: #e728ea;
$vocals-alto-color: #ee7630;
$vocals-soprano-color: #28eab6;
$vocals-eg-color: #28eab6;

$danger-color: #f14668;

$img-height: 205px;
$img-height-mobile: 86px;

// Store
$store-purple: #855bd4;

$store-fake-tabs-gap: 150px;
