@import './../sass/reuse';

.song:not(.flat) {
  cursor: pointer;
  text-align: center;

  ul {
    visibility: hidden;
    will-change: opacity, visibility;
    transition: opacity $duration $function;
    opacity: 0;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    border-top-right-radius: $gap / 4;
    border-top-left-radius: $gap / 4;
    background-color: #1a1a1a;
    margin: 0;
    padding: 0 12px;

    li {
      button {
        font-size: 17px;
        padding: 6px;
        line-height: 1;
        opacity: var(--song-actions-button-opacity);
        &:hover {
          opacity: 1;
          color: $white;
        }

        &.isActiveButton {
          opacity: 1;
        }
      }
    }
  }

  .isOverflown {
    &::before {
      background-color: $block-bg;
    }
  }

  .title {
    header {
      &:after {
        display: none;
      }
    }
  }

  .titleHolder {
    > * {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    strong::after {
      content: unset;
    }
  }

  button {
    text-align: center;
  }

  .play {
    will-change: opacity, visibility;
    transition: opacity $duration $function;
    visibility: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    will-change: transform;
    transition: transform $duration $function;
  }

  .blob {
    position: absolute;
    content: ' ';
    top: -11px;
    left: -11px;
    padding: 35px;
    border-radius: 50%;
    border: 2px solid $purple;
    animation: blob 5s linear infinite;

    @include mobile {
      padding: 24px;
    }
  }

  @keyframes blob {
    0%,
    100% {
      border-radius: 64% 36% 27% 73% / 55% 58% 42% 45%;
    }
    25% {
      border-radius: 73% 27% 26% 38% / 73% 36% 64% 27%;
    }
    50% {
      border-radius: 28% 72% 44% 56% / 49% 40% 60% 51%;
    }
    75% {
      border-radius: 40% 60% 70% 30% / 47% 62% 38% 53%;
    }
  }

  figure {
    position: relative;
    margin-bottom: $gap / 2;

    img {
      @include worship-transition(filter);
    }

    @include mobile {
      margin-bottom: 6px;
    }
    a span {
      display: contents !important;
      box-shadow: 0 14px 17px var(--song-box-shadow-color);
    }
  }

  &.isActive,
  &.isHovered {
    .play,
    ul {
      visibility: visible;
      opacity: 1;
    }
  }
  &.isHovered {
    img {
      filter: brightness(var(--song-hover-brightness));
    }
  }
  &.isActive {
    .play {
      @include worship-gradient;
    }
  }
}

$learn-width-desktop: 144px;

.flat {
  margin-bottom: $gap / 4;
  background-color: var(--song-background-color);
  border-radius: $block-radius;
  text-align: left;
  border-radius: 40px;
  padding: 1em;
  flex-wrap: wrap;

  @include desktop {
    padding: 8px;
  }

  @include widescreen {
    padding: 1em;
  }

  @include tablet {
    display: flex;
    align-items: center;
  }

  @include mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;

    figure {
      padding-right: 64px;
      figcaption {
        left: initial !important;
        right: 0 !important;
      }
    }
  }

  .learn {
    flex: 1 0;
  }

  &:not(.inList) {
    @include tablet {
      .songContainer {
        max-width: 70%;
      }
    }
  }
  .songContainer {
    display: flex;
    align-items: center;
    > * {
      margin-right: $gap / 4;
    }

    @include tablet {
      flex: 6;
    }

    @include desktop {
      flex: 3;
    }

    @include mobile {
      flex: 0 0 calc(100% - 75px);
    }
  }

  figcaption {
    margin-bottom: $gap / 4;

    @include desktop {
      margin-bottom: 0;
    }

    @include mobile {
      margin-bottom: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.isActive {
    overflow: hidden;
    box-shadow: 0 0 0 3px $cyan;

    .play,
    .learn {
      @include worship-gradient;
      color: #fff;
    }
  }

  .title {
    @include tablet {
      font-size: 15px;
    }
  }

  .songActionButton {
    margin-left: 20px;
    cursor: pointer;
    opacity: var(--song-actions-button-opacity);
    &:hover {
      opacity: var(--song-actions-button-opacity-hover);
    }
    &.isActiveButton {
      opacity: 1;
      color: $purple;
    }
  }
}

.inList {
  border-radius: 10px;
  padding: 17px 24px;
  margin-bottom: 20px;
  flex-direction: row !important;
  align-items: center !important;

  @include mobile {
    padding: 17px;
  }

  .learn {
    align-self: center !important;
    flex: 0 0 144px;
    @include fullhd {
      flex: 0 0 211px;
    }
  }

  .songContainer {
    margin-bottom: 0;
    flex-wrap: wrap;
    margin-right: 20px;
    width: calc(100% - 200px);

    @include fullhd {
      margin-right: 50px;
      max-width: calc(100% - 259px);
    }

    @include mobile {
      max-width: 100%;
      margin-bottom: 0;
      max-width: 100%;
    }

    .songInfo {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      @include widescreen {
        flex-direction: row;
      }
    }

    .songDetails {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      span {
        margin-right: 15px;
        &:first-child {
          font-weight: bold;
          white-space: nowrap;
        }
        @include mobile {
          display: none;
        }
      }
      @include widescreen {
        margin-left: 15px;
        justify-content: space-evenly;
      }
    }

    figure {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 0;

      .songImage {
        width: 82px;
        height: 82px;
        padding-bottom: 0;
        margin-right: 16px;

        @include mobile {
          margin-right: 0;
          width: 62px;
          height: 62px;
        }

        span:first-child {
          display: contents !important;
        }
      }

      button {
        background-color: var(--button-background-color);
      }
    }
    figcaption {
      position: absolute;
      left: 16px;
    }
    .songActions {
      @include mobile {
        display: none;
      }
      li {
        margin-left: 30px;
      }
    }

    .titleHolder {
      flex-direction: column;

      @include mobile {
        margin-left: 28px;
        strong {
          font-size: 12px;
        }
        span {
          display: none;
        }
      }
    }

    @include desktop {
      & + a {
        align-self: flex-end;
        width: 211px;
        max-width: 211px;
      }
    }

    @include widescreen {
      .titleHolder {
        white-space: nowrap;
        flex-direction: row;
      }
    }

    @include fullhd {
      & + a {
        align-self: center;
      }
      figure {
        margin-right: $gap * 2;

        .songImage {
          margin-right: $gap * 2;
        }
      }

      figcaption {
        position: static;
        left: 0;
      }
    }

    @include fullhd {
      margin-right: 50px;
      margin-bottom: 0;
    }
  }

  .title {
    flex: 2;
    position: relative;

    &:not(.isOverflown) {
      .titleHolder {
        display: flex;
        justify-content: space-between;
      }
    }

    &:after {
      content: unset;
    }

    strong {
      &:after {
        content: unset;
      }
    }

    strong,
    span {
      font-size: 17px;
    }

    @include tablet-only {
      strong,
      span {
        font-size: 14px;
      }
    }
  }

  .songActionButton {
    opacity: var(--song-actions-button-opacity);
    &:hover {
      opacity: var(--song-actions-button-opacity-hover);
    }
    &.isActiveButton {
      color: $purple;
    }
  }
}

.songTitleLink {
  color: var(--song-title-link-color);
  overflow: hidden;
  flex: 1 1;
  @include tablet-only {
    max-height: 38px;
  }
}

.title {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;

  @include mobile {
    font-size: 11px;
  }

  &:not(.isOverflown) {
    .titleHolder + .titleHolder {
      display: none;
    }
  }

  strong {
    font-weight: $weight-bold;
    margin-right: $gap / 4;

    &:after {
      content: '|';
      margin-left: $gap / 4;
      font-weight: normal;
    }
  }
}

.songActions {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: $gap / 4;

  @include mobile {
    justify-content: center;
    margin-right: 0;
  }

  li {
    margin-left: $gap / 2;

    &:first-child {
      margin-left: 0;
    }

    @include tablet {
      margin-left: $gap / 4;
    }

    @include mobile {
      position: relative;
      top: -2px;
      margin-left: 8px;
    }
  }
  a {
    margin-bottom: 0;
  }
}

.isDisabled {
  opacity: 0.4 !important;
  pointer-events: none;
}

.songActionButton {
  background: none;
  color: var(--button-background-color);
  border: none;
  padding: 0;
  font-size: 20px;

  @include mobile {
    height: 20px;
    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.isActiveButton {
  color: $purple;
}

$play-button-size: 50px;

.play {
  width: $play-button-size;
  height: $play-button-size;
  border-radius: $play-button-size;
  text-align: center;
  color: var(--play-button-color);
  padding: 0;
  flex: 0 0 $play-button-size;
  border: 0;
  background-color: var(--play-button-background-color);
  font-size: 16px;

  &:hover {
    @include worship-gradient;
    color: $white;
  }

  [data-icon='play'] {
    margin-top: 4px;
    margin-left: 4px;
  }
  [data-icon='pause'] {
    margin-top: 4px;
    margin-left: 1px;
  }

  @include mobile {
    width: 30px;
    height: 30px;
    [data-icon='play'] {
      width: 10px;
      height: 10px;
      position: relative;
      top: -2px;
      margin-left: 2px;
    }
    [data-icon='pause'] {
      width: 10px;
      height: 10px;
      position: relative;
      top: -1px;
      margin-left: 2px;
    }
  }
}

.song {
  .learn {
    display: block;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    border: 0;
    @include widescreen {
      width: $learn-width-desktop;
    }

    @include mobile {
      display: none;
    }
  }
  .editButton {
    position: absolute;
    z-index: 1;
    top: 70%;
    left: calc(50% - 10px);
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;

    @include tablet {
      top: 80%;
    }

    svg {
      width: 15px;
      height: 15px;
      cursor: pointer;

      @include tablet {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.setListSongTitle {
  font-weight: $weight-light;
  text-transform: none;
  @include widescreen {
    font-size: 47px;
  }
}

.setListArtist {
  font-weight: $weight-extra-bold;
  text-transform: none;
  @include widescreen {
    font-size: 34px;
  }
}

@mixin gradient {
  background: linear-gradient(
    to left,
    transparent 0%,
    $body-background-color 100%
  );
}

.isOverflown {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.hasEllipsis {
    &::before {
      visibility: visible;
    }
  }

  &::before {
    content: '...';
    position: absolute;
    visibility: hidden;
    right: 0;
    background-color: var(--song-background-color);
    padding: 0 1px 0 2px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .titleHolder {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-shrink: 0;
    justify-content: space-between;
    display: flex;
    + .titleHolder {
      margin-left: 50px;
    }
  }

  .titleHolderContainer {
    display: flex;
    max-width: 100%;
  }
}

.adForm {
  padding: $gap;

  .imgContainer {
    display: flex;
    margin-right: 58px;

    @include mobile {
      min-width: 100px;
      width: 100px;
      height: 100px;
      margin-right: 30px;
    }
    img {
      min-width: 205px;
    }
  }

  @include mobile {
    padding: 30px;

    figure {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }
  }

  @include widescreen {
    padding: $gap * 2;
  }

  .addSetlist {
    display: flex;
    justify-content: space-between;
    a {
      font-weight: 500;

      @include widescreen {
        font-size: 27px;
      }
    }
  }
}

.draftSong {
  position: relative;
  .songLink {
    &::before {
      top: calc(50% - 35px);
      left: 0;
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 70px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      content: 'draft';
      background: $white;
      color: #000000;
      font-family: 'Avenir Next';
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: 4px;
      text-transform: uppercase;
      transition: 0.4s top;
      @include mobile {
        height: 40px !important;
        top: calc(50% - 20px);
      }
    }
  }
  img {
    filter: grayscale(1);
  }
  .draftSongButtons {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s opacity 0.4s;
  }
  .editButton {
    position: absolute;
    z-index: 1;
    top: calc(50% + 60px);
    left: calc(50% - 15px);
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    @include mobile {
      top: calc(50% + 35px);
    }
  }
}

.listDraftSong {
  .songLink {
    &::before {
      height: 26px !important;
      font-size: 10px;
      top: calc(50% - 13px);
      letter-spacing: 2px;
      cursor: pointer;
    }
  }
}
