@import '../../sass/reuse';

.devicesModalModifier {
  padding: 12px 40px !important;
  @include tablet {
    padding: 25px 60px !important;
  }
  @include desktop {
    padding: 25px 80px !important;
  }
  &,
  > div {
    background-color: var(--modal-devices-background-color);
    border-radius: 30px;
    box-shadow: none;
    padding-bottom: 50px;
  }
  margin-bottom: 100px;

  header {
    h1 {
      color: var(--body-color);
      margin-left: 0;
      font-size: 24px;
      font-weight: 700;
      flex: 1;
      text-align: center;
      text-transform: none;
      @include tablet {
        font-size: 30px;
      }
      @include desktop {
        font-size: 36px;
      }
    }
    button {
      transform: translate(50px, -18px);
      svg {
        width: 18px;
        height: 18px;
        @include tablet {
          width: 38px;
          height: 38px;
        }
      }
    }
  }
}
