@import './../sass/reuse';

.banner {
  background: linear-gradient(90deg, #8e6dcd 0%, #79b1e6 100%);
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 7;

  a {
    position: absolute;
    top: 0;
    background-color: white;
    padding: 12px 36px;
    z-index: 7;
    border-radius: 0 0 7px 7px;
    font-size: 11px;
    text-transform: uppercase;
    color: #8854d1;
    letter-spacing: 0.25em;
    font-weight: 700;
    cursor: pointer;
  }
}
