@import './../sass/reuse';
.loader {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 5;

  .logo {
    display: flex;
    margin-bottom: 32px;
  }
}

.svg {
  position: absolute;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.svgCircleBg {
  fill: none;
}

.svgCircle {
  fill: none;
  transform: rotate(-90deg);
  transform-origin: center center;
}

.svgCircleText {
  font-size: 2rem;
  text-anchor: middle;
  fill: #fff;
  font-weight: bold;
}

.progressContainer {
  height: 5px;
  width: 100%;

  &.isFlat {
    margin-bottom: -28px;

    @include desktop-only {
      margin-bottom: -13px;
    }
  }
  &:not(.isFlat) {
    position: absolute;
    bottom: 0;
  }
}

.progressFiller {
  height: 100%;
  background-color: $cyan;

  &.isFlat {
    background-color: $purple;
  }
}
