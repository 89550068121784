@import './../sass/reuse';

.block {
  max-width: 100%;

  .header {
    align-items: center;
    display: flex;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include mobile {
      padding: 0;
    }
  }

  &.has-no-padding {
    .blockContainer {
      padding: 0;
    }
  }
}

.moreLink {
  margin-left: auto;
  font-size: $size-7;
  font-weight: $weight-bold;
  letter-spacing: 2.8px;

  @include desktop {
    font-size: 14px;
    margin-right: $gap;
  }

  @include mobile {
    font-size: 10px;
  }
}

.blockContainer {
  padding: $gap / 2;
  border-radius: $block-radius;
  background-color: $block-bg;
  flex: 1;

  @include tablet {
    padding: $gap;
  }

  @include fullhd {
    padding: $gap;
  }

  @include mobile {
    padding: 12px 12px 15px !important;
    article {
      div {
        a {
          max-width: 130px;
          span,
          strong {
            font-size: 9px !important;
          }
        }
      }
      figure {
        padding-right: 0;
      }
      & > a {
        display: block !important;
        font-size: 8px;
        padding: 7px 13px;
      }
    }
  }

  &.is-flat {
    padding: $gap / 2;
  }
}
