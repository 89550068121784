@import '../../sass/reuse';

.devicesContainer {
  background-color: transparent;

  h2 {
    color: $purple;
    text-transform: none;
    text-align: center;
    margin-bottom: 40px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0em;

    @include tablet {
      font-size: 20px;
      margin-bottom: 60px;
    }
    @include desktop {
      font-size: 28px;
      margin-bottom: 70px;
    }
  }
}

.tableHeader {
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;

  @include desktop {
    font-size: 19px;
  }
}
.deviceRow {
  padding: 22px 0px;
  align-items: center;
  font-size: 7px;
  font-weight: 400;
  text-transform: capitalize;
  border-bottom: 1px solid #666;

  @include tablet {
    border-bottom: 1px solid var(--body-color);
    font-size: 14px;
  }
  @include desktop {
    font-size: 19px;
    padding: 36px 0px;
  }
  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.currentDeviceRow {
  background-color: var(--modal-selected-row-color);
}
.currentDeviceLabel {
  font-weight: bold;
  display: block;
}
.removeButton {
  cursor: pointer;
  &:hover {
    svg {
      color: #fff;
    }
  }
}
.removeIcon {
  font-size: 20px;
  @include tablet {
    font-size: 22px;
  }
}
